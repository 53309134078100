<template lang="pug">
  main#main.notifications-list
    b-container(fluid)
      section.l-section
        .l-section-h
          b-card.notifications-list.card-white.card-no-gutters.shadow.h-100(
            footer-bg-variant='white'
            footer-border-variant='white'
            no-body)
</template>

<script>
import Table from '@common/table'
// import apiNotifications from '@services/api/notifications'

export default {
  name: 'notifications-list',
  mixins: [Table],
  props: {
    wns: {
      type: String,
      default: 'not',
    },
  },
}
</script>
